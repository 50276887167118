import React from 'react'
import ReactMarkdown from 'react-markdown'
import { graphql } from 'gatsby'

import removeMd from "remove-markdown"

import Seo from '../components/SEO/Seo'
import Heading from '../components/Heading/Heading'

const articleTemplate = ( { data: { strapi: { article } }, location } ) => {

	//ReactMarkdown accepts custom renderers
	const renderers = {
		//This custom renderer changes how images are rendered
		//we use it to constrain the max width of an image to its container
		p: ({ node, children }) => {
			if (node.children[0].tagName === 'img') {
				const image = node.children[ 0 ]
				return (
					React.createElement('img', { alt: image.properties.alt, src: image.properties.src, className: "article__article-image" })
				)
			}
			// Return default child if it's not an image
			return <p>{ children }</p>
		}
	};

	// Remove markdown from description then remove any line breaks
	const metaDescription = article.description ? removeMd( article.description ).replace(/[\r\n]+/gm, " ").substring(0, 160) : null

	return (
		<>
			<Seo
				title={ article.title }
				description={ metaDescription }
				pathname={ location.pathname } />

			<div className="article">

				<Heading heading={ article.title } />

				<div className="article__wrapper wrapper">

					<ReactMarkdown
						className="article__article-body"
						children={ article.description }
						components={ renderers } />

				</div>
			</div>
		</>
	)
}

export default articleTemplate

export const query = graphql`
	query ArticleQuery($id: ID!) {
		strapi {
			article( id: $id ) {
				title
				description 
			}
		}
	}
`